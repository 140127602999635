<template>
  <v-theme-provider dark>
    <base-section
      id="info-alt"
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="5"
          >
            <base-info />

            <base-business-contact />
          </v-col>

          <v-col
            class="text-center hidden-sm-and-down"
            cols="2"
          >
            <v-divider vertical />
          </v-col>

          <v-col
            cols="12"
            md="5"
          >
            <!--<base-contact-form subtitle="Drop us a Line" />-->
            <base-interes subtitle="" />
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  import BaseInteres from "../../components/base/Interes";
  export default {
    name: 'SectionBusinessInfo',
    components: {BaseInteres},
  }
</script>
